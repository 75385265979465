import {analyticsTags} from './../../utils/Analytics/AnalyticsTags';
import {trackCustomEvent} from './../../utils/Analytics/AdobeAnalyticsUtils';
import {put, takeLatest} from 'redux-saga/effects';
import {NetworkManager} from '../../api/NetworkManager';
import {
  getProfileDataSuccess,
  getProfileDataFailure,
  updateProfileDataSuccess,
  updateProfileDataFailure,
} from './action';
import {GET_PROFILE, UPDATE_PROFILE_INFO} from './constant';

export function* getViewProfileData(action) {
  try {
    const response = yield NetworkManager.getProfileData(action.payload.token);
    if (response) {
      yield put(getProfileDataSuccess(response.data));
    }
  } catch (error) {
    yield put(getProfileDataFailure(error));
  }
}

export function* updateViewProfileData(action) {
  try {
    const response = yield NetworkManager.updateProfileData(
      action.payload.data,
      action.payload.token,
    );
    if (response.status === 204) {
      const getResponse = yield NetworkManager.getProfileData(
        action.payload.token,
      );
      if (getResponse) {
        if (action.payload?.data?.contactInfo) {
          trackCustomEvent(
            analyticsTags.CaptureContactInformationSubmitSuccessEventTags,
          );
        } else if (action.payload?.data?.userQuestion) {
          trackCustomEvent(
            analyticsTags.CaptureSecurityQuestionSubmitSuccessEventTags,
          );
        } else if (action.payload?.data?.logonInfo?.newPassword) {
          trackCustomEvent(
            analyticsTags.CaptureChangePasswordSubmitSuccessEventTags,
          );
        } else if (action.payload?.data?.logonInfo?.newLogonId) {
          trackCustomEvent(
            analyticsTags.CaptureChangeUserIDSubmitSuccessEventTags,
          );
        }
        yield put(getProfileDataSuccess(getResponse.data));
        yield put(updateProfileDataSuccess(true));
      }
    } else {
      if (action.payload?.data?.contactInfo) {
        trackCustomEvent(
          analyticsTags.CaptureContactInformationSubmitFailureEventTags,
        );
      } else if (action.payload?.data?.userQuestion) {
        trackCustomEvent(
          analyticsTags.CaptureSecurityQuestionSubmitFailureEventTags,
        );
      } else if (action.payload?.data?.logonInfo?.newPassword) {
        trackCustomEvent(
          analyticsTags.CaptureChangePasswordSubmitFailureEventTags,
        );
      }
      yield put(updateProfileDataFailure(response));
    }
  } catch (error) {
    if (action.payload?.data?.contactInfo) {
      trackCustomEvent(
        analyticsTags.CaptureContactInformationSubmitFailureEventTags,
      );
    } else if (action.payload?.data?.userQuestion) {
      trackCustomEvent(
        analyticsTags.CaptureSecurityQuestionSubmitFailureEventTags,
      );
    } else if (action.payload?.data?.logonInfo?.newPassword) {
      trackCustomEvent(
        analyticsTags.CaptureChangePasswordSubmitFailureEventTags,
      );
    }
    yield put(updateProfileDataFailure(error));
  }
}

export function* watchProfileSaga() {
  yield takeLatest(GET_PROFILE, getViewProfileData);
  yield takeLatest(UPDATE_PROFILE_INFO, updateViewProfileData);
}
