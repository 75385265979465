import {put, takeLatest} from 'redux-saga/effects';
import {NetworkManager} from '../../api/NetworkManager';
import {
  getInvestmentOptionsSuccess,
  getInvestmentOptionsFailure,
} from './action';
import {GET_INVESTMENT_OPTIONS} from './constant';

export function* getInvestmentOptionsSaga(action) {
  try {
    const response = yield NetworkManager.getInvestmentOptions(
      action.payload.id,
      action.payload.token,
    );
    if (response) {
      yield put(getInvestmentOptionsSuccess(response.data));
    }
  } catch (error) {
    yield put(getInvestmentOptionsFailure(error));
  }
}

export function* watchInvestmentOptionsSaga() {
  yield takeLatest(GET_INVESTMENT_OPTIONS, getInvestmentOptionsSaga);
}
