import {createSlice} from '@reduxjs/toolkit';
import {PolicyServiceRequest} from './types';

export interface State {
  data: undefined | any;
  error: undefined | any;
}

const initialState: State = {
  data: undefined,
  error: undefined,
};

export const counterSlice = createSlice({
  name: PolicyServiceRequest,
  initialState,
  reducers: {
    getAEMData: (state) => {
      state;
    },
    aemApiCallSuccess: (state, action) => {
      state.data = action.payload;
      if (state.error !== undefined) {
        state.error = undefined;
      }
    },
    aemApiCallFailure: (state, action) => {
      if (state.data !== undefined) {
        state.data = undefined;
      }
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {getAEMData, aemApiCallSuccess, aemApiCallFailure} =
  counterSlice.actions;

export default counterSlice.reducer;
