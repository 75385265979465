import {
  GET_INVESTMENT_OPTIONS,
  GET_INVESTMENT_OPTIONS_SUCCESS,
  GET_INVESTMENT_OPTIONS_FAILURE,
  SET_TRANSACTION_CONFIRMED_TRUE,
  SET_CANCEL_CLICKED_TRUE,
  SET_CANCEL_CLICKED_FALSE,
} from './constant';

const initialState = {
  getInvestmentOptionsData: {},
  token: {},
  isError: false,
  error: '',
  loading: true,
  cancelClicked: false,
};

const investmentOptionsReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case GET_INVESTMENT_OPTIONS:
      return {
        ...state,
        token: payload.token,
        isError: false,
        loading: true,
      };
    case GET_INVESTMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        getInvestmentOptionData: payload,
        isError: false,
        loading: false,
      };
    case GET_INVESTMENT_OPTIONS_FAILURE:
      return {
        ...state,
        error: payload.error,
        isError: true,
        loading: false,
      };
    case SET_TRANSACTION_CONFIRMED_TRUE:
      return {
        ...state,
        isTransactionConfirmed: true,
      };
    case SET_CANCEL_CLICKED_TRUE:
      return {
        ...state,
        cancelClicked: true,
      };
    case SET_CANCEL_CLICKED_FALSE:
      return {
        ...state,
        cancelClicked: false,
      };
    default:
      return state;
  }
};
export default investmentOptionsReducer;
