import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE_INFO,
  UPDATE_PROFILE_INFO_SUCCESS,
  UPDATE_PROFILE_INFO_FAILURE,
  UPDATE_PROFILE_INFO_RESET,
  USER_DETAILS,
} from './constant';

export const getProfileData = (token) => ({
  type: GET_PROFILE,
  payload: {token},
});

export const getProfileDataSuccess = (data) => ({
  type: GET_PROFILE_SUCCESS,
  payload: {data},
});
export const getProfileDataFailure = (error) => ({
  type: GET_PROFILE_FAILURE,
  payload: {error},
});

export const updateProfileData = (data, token) => ({
  type: UPDATE_PROFILE_INFO,
  payload: {data, token},
});

export const updateProfileDataSuccess = (data) => ({
  type: UPDATE_PROFILE_INFO_SUCCESS,
  payload: {data},
});

export const updateProfileDataFailure = (error) => ({
  type: UPDATE_PROFILE_INFO_FAILURE,
  payload: {error},
});
export const getUserDetails = (data) => ({
  type: USER_DETAILS,
  payload: data,
});

export const updateProfileDataReset = () => ({
  type: UPDATE_PROFILE_INFO_RESET,
});
