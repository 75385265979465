import {put, takeLatest} from 'redux-saga/effects';
import {NetworkManager} from '../../api/NetworkManager';
import {getNotificationSuccess, getNotificationFailure} from './actions';
import {GET_NOTIFICATION_REQUEST} from './constant';

export function* getNotificationDataSaga() {
  try {
    const response = yield NetworkManager.getAEMNotification();
    if (response) {
      yield put(
        getNotificationSuccess(
          response.data[':items'].root[':items'].notification,
        ),
      );
    }
  } catch (error) {
    yield put(getNotificationFailure(error));
  }
}

export function* watchNotificationSaga() {
  yield takeLatest(GET_NOTIFICATION_REQUEST, getNotificationDataSaga);
}
