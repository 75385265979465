import {
  GET_GENERIC_NOTIFICATION_REQUEST,
  GET_GENERIC_NOTIFICATION_SUCCESS,
  GET_GENERIC_NOTIFICATION_FAILURE,
} from './constant';

export const getGenericNotificationRequest = () => ({
  type: GET_GENERIC_NOTIFICATION_REQUEST,
});

export const getGenericNotificationSuccess = (notificationData) => ({
  type: GET_GENERIC_NOTIFICATION_SUCCESS,
  payload: notificationData,
});

export const getGenericNotificationFailure = (error) => ({
  type: GET_GENERIC_NOTIFICATION_FAILURE,
  payload: error,
});
