import {createSlice} from '@reduxjs/toolkit';
import {PolicyList} from './types';

export interface State {
  list: undefined | any;
  selectedPolicy: any;
  error: undefined | any;
}

const initialState: State = {
  list: undefined,
  selectedPolicy: undefined,
  error: undefined,
};

export const counterSlice = createSlice({
  name: PolicyList,
  initialState,
  reducers: {
    getPolicyList: (state) => {
      state;
    },
    setSelectedPolicy: (state, action) => {
      state.selectedPolicy = action.payload;
    },
    policyListApiSuccess: (state, action) => {
      state.list = Array.isArray(action.payload.policyList)
        ? action.payload.policyList
        : [];

      const selectedPolicy: string = action.payload?.selectedPolicy;

      const policyExists = selectedPolicy
        ? (state.list as Array<any>).find(
            ({policyPlanNumber}) => policyPlanNumber === selectedPolicy,
          )
        : undefined;

      if (action.payload.policyList[0]) {
        state.selectedPolicy = policyExists
          ? policyExists
          : action.payload.policyList[0];
      }

      if (state.error !== undefined) {
        state.error = undefined;
      }
    },
    policyListApiFailure: (state, action) => {
      if (state.list !== undefined) {
        state.list = undefined;
        state.selectedPolicy = undefined;
      }
      state.error = action.payload;
    },
  },
});

export const {
  getPolicyList,
  setSelectedPolicy,
  policyListApiSuccess,
  policyListApiFailure,
} = counterSlice.actions;

export default counterSlice.reducer;
