import {put, takeLatest} from 'redux-saga/effects';
import {NetworkManager} from '../../api/NetworkManager';
import {policyListApiSuccess, policyListApiFailure} from './policyListsSlice';
import {GET_POLICY_LIST_DATA} from './types';

export function* getPolicyListRequestData(action) {
  try {
    const response = yield NetworkManager.getPolicyLists(action.payload.token);
    if (response) {
      yield put(
        policyListApiSuccess({
          ...response.data,
          selectedPolicy: action.payload?.selectedPolicy,
        }),
      );
    }
  } catch (error) {
    yield put(policyListApiFailure(error));
  }
}

export function* watchPolicyListRequest() {
  yield takeLatest(GET_POLICY_LIST_DATA, getPolicyListRequestData);
}
