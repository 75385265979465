import {
  GET_IDO_LIST,
  GET_IDO_LIST_SUCCESS,
  GET_IDO_LIST_FAILURE,
} from './constant';

const initialState = {
  getPoliciesListData: {},
  token: {},
  isError: false,
  error: '',
};

const indexedDividendReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case GET_IDO_LIST:
      return {
        ...state,
        token: payload.token,
        isError: false,
      };
    case GET_IDO_LIST_SUCCESS:
      return {
        ...state,
        getPoliciesListData: payload,
        isError: false,
      };
    case GET_IDO_LIST_FAILURE:
      return {
        ...state,
        error: payload.error,
        isError: true,
      };
    default:
      return state;
  }
};
export default indexedDividendReducer;
