import {put, takeLatest} from 'redux-saga/effects';
import {NetworkManager} from '../../api/NetworkManager';
import {getPoliciesListSuccess, getPoliciesListFailure} from './action';
import {GET_POLICIES_SUMMARY} from './constant';

export function* getPoliciesListSaga(action) {
  try {
    let response = yield NetworkManager.getPolicyDetails(
      action.payload.id,
      action.payload.token,
    );
    if (response && response.status == 200) {
      yield put(getPoliciesListSuccess(response.data));
    } else {
      yield put(getPoliciesListFailure(response));
    }
  } catch (error) {
    yield put(getPoliciesListFailure(error));
  }
}

export function* watchPoliciesSaga() {
  yield takeLatest(GET_POLICIES_SUMMARY, getPoliciesListSaga);
}
