import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  UPDATE_PROFILE_INFO,
  UPDATE_PROFILE_INFO_SUCCESS,
  UPDATE_PROFILE_INFO_FAILURE,
  UPDATE_PROFILE_INFO_RESET,
  USER_DETAILS,
} from './constant';

const initialState = {
  getProfileResponsiveData: {},
  isProfileUpdated: false,
  token: {},
  isError: false,
  error: {},
  loading: true,
  userDetails: {},
};

const profileDataReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case GET_PROFILE:
      return {
        ...state,
        token: payload.token,
        isError: false,
        loading: true,
      };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileResponsiveData: payload,
        isError: false,
        loading: false,
      };
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        error: payload.error,
        isError: true,
        loading: false,
      };
    case UPDATE_PROFILE_INFO:
      return {
        ...state,
        token: payload.token,
        data: payload.data,
        isError: false,
        loading: true,
      };
    case UPDATE_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        isProfileUpdated: payload,
        isError: false,
        loading: false,
      };
    case UPDATE_PROFILE_INFO_FAILURE:
      return {
        ...state,
        error: payload.error,
        isProfileUpdated: false,
        isError: true,
        loading: false,
      };
    case UPDATE_PROFILE_INFO_RESET:
      return {
        ...state,
        isProfileUpdated: false,
        isError: false,
        error: {},
      };
    case USER_DETAILS:
      return {
        ...state,
        userDetails: payload,
      };
    default:
      return state;
  }
};
export default profileDataReducer;
