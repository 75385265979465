import {
  GET_POLICIES_SUMMARY,
  GET_POLICIES_SUMMARY_SUCCESS,
  GET_POLICIES_SUMMARY_FAILURE,
} from './constant';

export const getPoliciesList = (id, token) => ({
  type: GET_POLICIES_SUMMARY,
  payload: {id, token},
});

export const getPoliciesListSuccess = (data) => ({
  type: GET_POLICIES_SUMMARY_SUCCESS,
  payload: {data},
});
export const getPoliciesListFailure = (error) => ({
  type: GET_POLICIES_SUMMARY_FAILURE,
  payload: {error},
});
