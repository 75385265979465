import {
  GET_INVESTMENT_OPTIONS,
  GET_INVESTMENT_OPTIONS_SUCCESS,
  GET_INVESTMENT_OPTIONS_FAILURE,
  SET_TRANSACTION_CONFIRMED_TRUE,
  SET_CANCEL_CLICKED_TRUE,
  SET_CANCEL_CLICKED_FALSE,
} from './constant';

export const getInvestmentOptions = (id, token) => ({
  type: GET_INVESTMENT_OPTIONS,
  payload: {id, token},
});

export const getInvestmentOptionsSuccess = (data) => ({
  type: GET_INVESTMENT_OPTIONS_SUCCESS,
  payload: {data},
});
export const getInvestmentOptionsFailure = (error) => ({
  type: GET_INVESTMENT_OPTIONS_FAILURE,
  payload: {error},
});

export const setTransactionConfirmedTrue = (flag) => ({
  type: SET_TRANSACTION_CONFIRMED_TRUE,
  payload: {flag},
});

export const setCancelClikedTrue = (flag) => ({
  type: SET_CANCEL_CLICKED_TRUE,
  payload: {flag},
});

export const setCancelClikedFalse = (flag) => ({
  type: SET_CANCEL_CLICKED_FALSE,
  payload: {flag},
});
