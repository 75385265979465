import {
  GET_GENERIC_NOTIFICATION_REQUEST,
  GET_GENERIC_NOTIFICATION_SUCCESS,
  GET_GENERIC_NOTIFICATION_FAILURE,
} from './constant';

const genericNotificationReducer = (
  state = {genericNotificationData: {}, loading: false, error: null},
  actions,
) => {
  switch (actions.type) {
    case GET_GENERIC_NOTIFICATION_REQUEST:
      return {...state, loading: true, error: null};
    case GET_GENERIC_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        genericNotificationData: actions.payload,
      };
    case GET_GENERIC_NOTIFICATION_FAILURE:
      return {...state, loading: true, error: actions.payload};
    default:
      return state;
  }
};

export default genericNotificationReducer;
