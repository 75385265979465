import {put, takeLatest} from 'redux-saga/effects';
import {NetworkManager} from '../../api/NetworkManager';
import {
  aemApiCallFailure,
  aemApiCallSuccess,
} from './policyServiceRequestSlice';
import {GET_POLICY_SERVICE_REQUEST_DATA} from './types';

export function* getPolicyServiceRequestData() {
  try {
    const response = yield NetworkManager.getPolicyServiceRequest();
    if (response) {
      yield put(aemApiCallSuccess(response.data));
    }
  } catch (error) {
    yield put(aemApiCallFailure(error));
  }
}

export function* watchPolicyServiceRequest() {
  yield takeLatest(
    GET_POLICY_SERVICE_REQUEST_DATA,
    getPolicyServiceRequestData,
  );
}
