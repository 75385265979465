import {
  GET_TRANSFER_TRANSACTION_REQUEST,
  GET_TRANSFER_TRANSACTION_SUCCESS,
  GET_TRANSFER_TRANSACTION_FAILURE,
  GET_DOLLAR_TRANSACTION_REQUEST,
  GET_DOLLAR_TRANSACTION_SUCCESS,
  GET_DOLLAR_TRANSACTION_FAILURE,
} from './constant';

export const getTransferBalanceRequest = (
  token,
  policyNumber,
  transactiontype,
) => ({
  type: GET_TRANSFER_TRANSACTION_REQUEST,
  payload: {token, policyNumber, transactiontype},
});
export const getTransferDollarRequest = (
  token,
  policyNumber,
  transactiontype,
) => ({
  type: GET_DOLLAR_TRANSACTION_REQUEST,
  payload: {token, policyNumber, transactiontype},
});
export const getTransferBalanceSuccess = (data) => ({
  type: GET_TRANSFER_TRANSACTION_SUCCESS,
  payload: {data},
});
export const getTransferBalanceFailure = (error) => ({
  type: GET_TRANSFER_TRANSACTION_FAILURE,
  payload: {error},
});
export const getTransferDollarSuccess = (data) => ({
  type: GET_DOLLAR_TRANSACTION_SUCCESS,
  payload: {data},
});
export const getTransferDollarRequestFailure = (error) => ({
  type: GET_DOLLAR_TRANSACTION_FAILURE,
  payload: {error},
});
