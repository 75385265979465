//RootSaga combined with all Modules's Saga

import {all} from 'redux-saga/effects';
import {watchInvestmentOptionsSaga} from './investmentoptions/saga';
import {watchPoliciesSaga} from './policies/saga';
import {watchPolicyListRequest} from './policylists/saga';
import {watchPolicyServiceRequest} from './policyservicerequest/saga';
import {watchProfileSaga} from './profileDetails/saga';
import {
  watchTransferTransaction,
  watchDollarTransferTransaction,
} from './TransferBalance/saga';
import {watchGenericNotificationSaga} from './genericnotification/saga';
import {watchNotificationSaga} from './notification/saga';

export default function* rootSaga() {
  yield all([
    watchPoliciesSaga(),
    watchPolicyServiceRequest(),
    watchPolicyListRequest(),
    watchProfileSaga(),
    watchInvestmentOptionsSaga(),
    watchTransferTransaction(),
    watchDollarTransferTransaction(),
    watchPolicyServiceRequest(),
    watchNotificationSaga(),
    watchGenericNotificationSaga(),
  ]);
}
