import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
} from './constant';

export const getNotificationRequest = () => ({
  type: GET_NOTIFICATION_REQUEST,
});

export const getNotificationSuccess = (notificationData) => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: notificationData,
});

export const getNotificationFailure = (error) => ({
  type: GET_NOTIFICATION_FAILURE,
  payload: error,
});
