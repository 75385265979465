import {put, takeLatest} from 'redux-saga/effects';
import {NetworkManager} from '../../api/NetworkManager';
import {
  getGenericNotificationSuccess,
  getGenericNotificationFailure,
} from './actions';
import {GET_GENERIC_NOTIFICATION_REQUEST} from './constant';

export function* getGenericNotificationDataSaga() {
  try {
    const response = yield NetworkManager.getAEMGenericNotification();
    if (response) {
      yield put(
        getGenericNotificationSuccess(
          response.data[':items'].root[':items'].notification,
        ),
      );
    }
  } catch (error) {
    yield put(getGenericNotificationFailure(error));
  }
}

export function* watchGenericNotificationSaga() {
  yield takeLatest(
    GET_GENERIC_NOTIFICATION_REQUEST,
    getGenericNotificationDataSaga,
  );
}
